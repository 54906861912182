import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import Image from '~/components/Image';
import styles from './BrandSaleUp.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, EffectCube, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/css';

const cx = classNames.bind(styles);
const listProduct = [
    {
        id: 0,
        link: '/phone',
        image: 'https://images.fpt.shop/unsafe/fit-in/214x212/filters:quality(90):fill(white)/fptshop.com.vn/Uploads/Originals/2022/10/28/638025477842393859_HTML-IMG.png',
        name: 'APPLE',
        sale: 25,
    },
    {
        id: 2,
        link: '/laptop',
        image: 'https://images.fpt.shop/unsafe/fit-in/214x212/filters:quality(90):fill(white)/fptshop.com.vn/Uploads/Originals/2022/10/6/638006647423918444_HTML-IMG-3.png',
        name: 'LAPTOP',
        sale: 12,
    },
    {
        id: 3,
        link: '/phone',
        image: 'https://images.fpt.shop/unsafe/fit-in/214x212/filters:quality(90):fill(white)/fptshop.com.vn/Uploads/Originals/2022/10/6/638006611719173825_HTML-IMG-2.png',
        name: 'PHONE',
        sale: 20,
    },
    {
        id: 4,
        link: '/phone',
        image: 'https://images.fpt.shop/unsafe/fit-in/214x212/filters:quality(90):fill(white)/fptshop.com.vn/Uploads/Originals/2022/10/6/638006650603301823_HTML-IMG-5.png',
        name: 'PHỤ KIỆN',
        sale: 10,
    },
    {
        id: 5,
        link: '/house-ware',
        image: 'https://images.fpt.shop/unsafe/fit-in/214x212/filters:quality(90):fill(white)/fptshop.com.vn/Uploads/Originals/2022/10/6/638006648509231290_HTML-IMG-4.png',
        name: 'ĐỒ GIA DỤNG',
        sale: 15,
    },
];
function BrandSaleUp() {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('container')}>
                <div className={cx('title')}>SIÊU HỘI MUA SẮM - SALE HẾT KẾT NĂM</div>
                <div className={cx('banner')}>
                    <Image
                        className={cx('banner-image')}
                        src="https://images.fpt.shop/unsafe/fit-in/1168x195/filters:quality(90):fill(white)/fptshop.com.vn/Uploads/Originals/2022/11/1/638028796106590979_F-H5_1200x200.png"
                    />
                </div>
                <div className={cx('list-brand')}>
                    {listProduct.map((item, index) => (
                        <div className={cx('brand-item')} key={index}>
                            <Link to={item.link}>
                                <Image src={item.image} />
                                <div className={cx('brand-btn-body')}>
                                    <div className={cx('brand-sale')}>
                                        <div className={cx('brand-btn')}>{item.name}</div>Giảm đến {item.sale}%
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
                <div className={cx('list-brand', 'm_list-brand')}>
                    <Swiper
                        // autoplay={{
                        //     delay: 4000,
                        //     disableOnInteraction: false,
                        // }}
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectCube, Autoplay]}
                        spaceBetween={10}
                        slidesPerView={2}
                        navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                        pagination={{ clickable: true }}
                        className={cx('m_list-brand-body')}
                    >
                        {listProduct.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className={cx('brand-item')} key={index}>
                                    <Link to={item.link}>
                                        <Image src={item.image} />
                                        <div className={cx('brand-btn-body')}>
                                            <div className={cx('brand-sale')}>
                                                <div className={cx('brand-btn')}>{item.name}</div>Giảm đến {item.sale}%
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </SwiperSlide>
                        ))}
                        <div className="swiper-button-prev"></div>
                        <div className="swiper-button-next"></div>
                    </Swiper>
                </div>
            </div>
        </div>
    );
}

export default BrandSaleUp;
