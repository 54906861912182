export const GET_ALL_PRODUCTS = 'get-all-products';
export const GET_BRAND_REDUX = 'get-brand-redux';
export const CHOOSE_BRAND_REDUX = 'choose-brand-redux';
export const SET_MESSAGE_REDUX = 'set-message-redux';
export const COUNT_MESSAGE_REDUX = 'count-message-redux';
export const ACTIVE_USER_REDUX = 'active-user-redux';
export const PRODUCT_VIEWED_REDUX = 'product-viewed-redux';
export const PRODUCT_BUY_REDUX = 'product-buy-redux';
export const SET_LANGUAGE = 'set_language';
export const SET_NEWS = 'set_news';
