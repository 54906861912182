import classNames from 'classnames/bind';
import { memo, useState } from 'react';
import Portal from '~/components/Portal';
import styles from './ModalNavMobile.module.scss';
import { AiOutlineClose, AiOutlineMenuUnfold, AiOutlineMessage } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import stylesHeader from '~/layouts/components/Header/Header.module.scss';
import stylesHeaderNav from '~/layouts/components/Header/HeaderNav.module.scss';
import Image from '../Image/Image';
import { BiArrowBack, BiHelpCircle, BiMoney, BiNotepad } from 'react-icons/bi';
import { FormattedMessage } from 'react-intl';
import { BsBell } from 'react-icons/bs';
import { actions } from '~/store';
import { useStore } from '~/hooks';
import { FiLogIn } from 'react-icons/fi';
import ModalOverlay from '../ModalOverlay/ModalOverlay';
import { GrUserAdmin } from 'react-icons/gr';

const cx = classNames.bind(styles);
const cxHeader = classNames.bind(stylesHeader);
const cxNav = classNames.bind(stylesHeaderNav);
function ModalNavMobile({ setIsModalNavMb }) {
    const [state, dispatch] = useStore();
    const [isModalLogin, setIsModalLogin] = useState(false);
    const handleLogo = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const handleMessage = () => {
        dispatch(actions.setMessageRedux(true));
        dispatch(actions.countMessageRedux(0));
    };
    return (
        <Portal>
            <div className={cx('m_wrapper')} onClick={() => setIsModalNavMb(false)}>
                {isModalLogin && <ModalOverlay setLogin={setIsModalLogin} />}
                <div className={cx('m_container')} onClick={(e) => e.stopPropagation()}>
                    <div className={cx('m_header')}>
                        <div className={cx('logo_m')}>
                            <Image
                                className={cx('logo-image', 'image')}
                                src="https://www.svgrepo.com/show/217771/shopping-logo.svg"
                                alt="TikTok"
                            />
                            <div className={cx('logo-cart', 'logo-cart_m')}>
                                <Link to="/" className={cx('logo-link')} onClick={handleLogo}>
                                    BinShop
                                </Link>
                            </div>
                        </div>
                        <div className={cx('icon-close')} onClick={() => setIsModalNavMb(false)}>
                            <AiOutlineClose className={cx('icon-close-item')} />
                        </div>
                    </div>
                    <div className={cx('m_body')}>
                        <div>
                            {!JSON.parse(localStorage.getItem('IS_LOGIN')) ? (
                                ''
                            ) : (
                                <div>
                                    <div className={cx('group', 'group-name')} onClick={() => setIsModalNavMb(false)}>
                                        <Link to="/user/account/profile" className={cx('group-link')}>
                                            <Image
                                                src={
                                                    JSON.parse(localStorage.getItem('USER_LOG_IN')) &&
                                                    JSON.parse(localStorage.getItem('USER_LOG_IN'))?.image
                                                }
                                                className={cx('user-avatar')}
                                            />
                                            <span className={cx('user-name')}>
                                                {JSON.parse(localStorage.getItem('USER_LOG_IN')) &&
                                                    JSON.parse(localStorage.getItem('USER_LOG_IN')).nickName}
                                            </span>
                                        </Link>
                                    </div>
                                    {/* notification */}
                                    <div className={cx('group')} onClick={() => setIsModalNavMb(false)}>
                                        <Link to="/user/account/profile" className={cx('group-link')}>
                                            <p
                                                className={cx('item')}
                                                onClick={() => dispatch(actions.activeUserRedux(1))}
                                            >
                                                <BsBell className={cx('item-icon')} />
                                                <FormattedMessage id="home.notification" />
                                            </p>
                                        </Link>
                                    </div>
                                    {/* message */}
                                    <div
                                        className={cx('group')}
                                        onClick={() => {
                                            handleMessage();
                                            setIsModalNavMb(false);
                                        }}
                                    >
                                        <p className={cx('item')}>
                                            <AiOutlineMessage className={cx('item-icon')} />
                                            <FormattedMessage id="home.message" />
                                        </p>
                                        {state.countMessageRedux == 0 || state.setMessage === true ? (
                                            ''
                                        ) : (
                                            <span className={cx('header-noti')}>{state.countMessageRedux}</span>
                                        )}
                                    </div>

                                    {/* manage admin */}
                                    <div className={cx('group')} onClick={() => setIsModalNavMb(false)}>
                                        <Link to="/admin" className={cx('group-link')}>
                                            <p
                                                className={cx('item')}
                                                onClick={() => dispatch(actions.activeUserRedux(1))}
                                            >
                                                <GrUserAdmin className={cx('item-icon')} />
                                                <FormattedMessage id="home.manageAdmin" />
                                            </p>
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
                            <div className={cx('group')} onClick={() => setIsModalNavMb(false)}>
                                <Link to="/tin-tuc/news" onClick={() => dispatch(actions.setNewsRedux(0))}>
                                    <p className={cx('item')}>
                                        <BiNotepad className={cx('item-icon')} />
                                        <div>Thông tin hay</div>
                                    </p>
                                </Link>
                            </div>
                            <div className={cx('group')} onClick={() => setIsModalNavMb(false)}>
                                <Link to="/dich-vu" onClick={() => dispatch(actions.setNewsRedux(0))}>
                                    <p className={cx('item')}>
                                        <BiMoney className={cx('item-icon')} />
                                        <div>Thanh toán & tiện ích</div>
                                    </p>
                                </Link>
                            </div>
                            <div className={cx('group')} onClick={() => setIsModalNavMb(false)}>
                                <Link to="/help" onClick={() => dispatch(actions.setNewsRedux(0))}>
                                    <p className={cx('item')}>
                                        <BiHelpCircle className={cx('item-icon')} />
                                        <FormattedMessage id="home.help" />
                                    </p>
                                </Link>
                            </div>
                            <div className={cx('group', 'btn-log')}>
                                {JSON.parse(localStorage.getItem('IS_LOGIN')) ? (
                                    <Link
                                        to="/"
                                        onClick={() => {
                                            localStorage.setItem('IS_LOGIN', JSON.stringify(false));
                                            localStorage.setItem('USER_LOG_IN', JSON.stringify(null));
                                            localStorage.setItem('TOKEN', JSON.stringify(null));
                                            window.location.reload();
                                        }}
                                    >
                                        <p className={cx('item')}>
                                            <FiLogIn className={cx('item-icon')} />
                                            <div className={cx('')}>
                                                <FormattedMessage id="home.logout" />
                                            </div>
                                        </p>
                                    </Link>
                                ) : (
                                    <Link
                                        to="/"
                                        onClick={() => {
                                            setIsModalLogin(true);
                                            dispatch(actions.setNewsRedux(0));
                                        }}
                                    >
                                        <p className={cx('item')}>
                                            <FiLogIn className={cx('item-icon')} />
                                            <div className={cx('')}>
                                                <FormattedMessage id="home.login" />
                                            </div>
                                        </p>
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Portal>
    );
}

export default memo(ModalNavMobile);
